
import React from "react";
import { Fade } from "./../../components/Animation";
import Carousel from "react-multi-carousel";
import Scrollspy from "react-scrollspy";
import Scroll from "../../pages/Scroll";
import Image from "next/image";
import QuickEasy from "../../public/images/home/simple-steps/simple-process.png";
import AIPowered from "../../public/images/home/ai/ai-powered-design.png";
import SolarBattery from "../../public/images/home/ai/solar-and-battery.png";
import GetFinanced from "../../public/images/home/simple-steps/get-financed_fast.png";
import GoSolar from "../../public/images/home/simple-steps/go-sign-in.png";

const responsive = {
    Desktop: {
        breakpoint: { max: 4000, min: 640 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 640, min: 0 },
        items: 1,
    },
};

const SectionSimplesteps = () => {
    return (
        <section className="custom-container px-4 md:px-0 py-8 md:py-12 xl:pt-20 xl:pb-10">
            <div className="xxs:text-center sm:px-6 max-w-5xl mx-auto">
                <h2 className="pb-2 md:pb-8 text-3xl xxs:text-4xl md:text-5xl xd:text-5xxl text-center font-sfBold text-titleNewColor">
                We Make Going Solar Online
                    <span className="text-primary"> Simple</span>
                </h2>
            </div>


            <div className="section-wrapper hidden sm:grid lg:grid-cols-5 md:gap-6 lg:gap-8 sm:pt-6 2md:pt-10  py-4 sm:grid-cols-2 grid-cols-1 gap-6">
                <div className="simple-step-card flex flex-col text-center items-center relative">
                    <div className="card-image-wrapper rounded-full flex p-4 justify-center items-center w-40 h-40 md:w-44 md:h-44 xd:w-52 xd:h-52">
                        <Fade triggerOnce delay={600}>
                            {/* <img
                                src="./images/home/simple-steps/simple-process.png"
                                alt="Quick and Easy Process"
                                className="object-cover w-20 md:w-20"
                            /> */}
                             <Image
                        alt=""
                        src={QuickEasy}
                        width="80"
                        height="88"
                        className="object-contain"
                      />
                        </Fade>
                    </div>
                    <div className="max-w-260 pt-2 md:pt-5 lg:pb-12 pb-4">
                        <h3 className="mb-1.5 md:mb-2 text-titleNewColor leading-6 text-xl xd:text-2xl font-sfBold xxs:px-6 lg:px-8">
                        Quick and Easy Process
                        </h3>
                        <p className="text-gray-500 font-sfMedium text-lg leading-6 xd:text-xl xxs:px-3">
                        Enter your address to start your solar journey.
                        </p>
                    </div>
                    <span className="hidden 2md:flex md:absolute simplestep-arrow-icon">
                        <svg className="w-4" width="27" height="19" viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.122 18.4888C15.7753 18.148 15.6089 17.7362 15.6227 17.2534C15.6378 16.7705 15.8186 16.3587 16.1653 16.0179L21.0626 11.204H1.73355C1.24238 11.204 0.830369 11.0404 0.497528 10.7133C0.165843 10.3872 0 9.98281 0 9.5C0 9.01719 0.165843 8.6122 0.497528 8.28502C0.830369 7.95898 1.24238 7.79596 1.73355 7.79596H21.0626L16.122 2.93946C15.7753 2.59865 15.6019 2.19366 15.6019 1.72448C15.6019 1.25644 15.7753 0.852017 16.122 0.51121C16.4687 0.170403 16.8807 0 17.358 0C17.8342 0 18.2456 0.170403 18.5923 0.51121L26.5233 8.30717C26.6966 8.47758 26.8197 8.66218 26.8925 8.86099C26.9642 9.05979 27 9.27279 27 9.5C27 9.7272 26.9642 9.94021 26.8925 10.139C26.8197 10.3378 26.6966 10.5224 26.5233 10.6928L18.549 18.5314C18.2311 18.8438 17.8342 19 17.358 19C16.8807 19 16.4687 18.8296 16.122 18.4888Z" fill="#838588" />
                        </svg></span>
                </div>

                <div className="simple-step-card flex flex-col text-center items-center relative">
                    <div className="card-image-wrapper rounded-full flex p-4 justify-center items-center w-40 h-40 md:w-44 md:h-44 xd:w-52 xd:h-52">
                        <Fade triggerOnce delay={500}>
                            {/* <img
                                src="./images/home/simple-steps/ai-powered-design.png"
                                alt="AI-Powered Design"
                                className="object-cover w-20 md:w-28"
                            /> */}
                            <Image
                                alt=""
                                src={AIPowered}
                                width="112"
                                height="83"
                                className="object-contain"
                            />
                        </Fade>
                    </div>
                    <div className="max-w-260 pt-2 md:pt-5 lg:pb-12 pb-4">
                        <h3 className="mb-1.5 md:mb-2 text-titleNewColor leading-6 text-xl xd:text-2xl font-sfBold xxs:px-6 mx-auto xxs:w-40 xd:w-52">
                        AI-Powered Design
                        </h3>
                        <p className="text-gray-500 font-sfMedium text-lg leading-6 xd:text-xl">
                        Our AI tool gives you a fully designed system tailored to your homes needs in the matter of seconds.
                        </p>
                    </div>
                    <span className="hidden 2md:flex md:absolute simplestep-arrow-icon md:hidden lg:flex">
                        <svg className="w-4" width="27" height="19" viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.122 18.4888C15.7753 18.148 15.6089 17.7362 15.6227 17.2534C15.6378 16.7705 15.8186 16.3587 16.1653 16.0179L21.0626 11.204H1.73355C1.24238 11.204 0.830369 11.0404 0.497528 10.7133C0.165843 10.3872 0 9.98281 0 9.5C0 9.01719 0.165843 8.6122 0.497528 8.28502C0.830369 7.95898 1.24238 7.79596 1.73355 7.79596H21.0626L16.122 2.93946C15.7753 2.59865 15.6019 2.19366 15.6019 1.72448C15.6019 1.25644 15.7753 0.852017 16.122 0.51121C16.4687 0.170403 16.8807 0 17.358 0C17.8342 0 18.2456 0.170403 18.5923 0.51121L26.5233 8.30717C26.6966 8.47758 26.8197 8.66218 26.8925 8.86099C26.9642 9.05979 27 9.27279 27 9.5C27 9.7272 26.9642 9.94021 26.8925 10.139C26.8197 10.3378 26.6966 10.5224 26.5233 10.6928L18.549 18.5314C18.2311 18.8438 17.8342 19 17.358 19C16.8807 19 16.4687 18.8296 16.122 18.4888Z" fill="#838588" />
                        </svg></span>
                </div>
                <div className="simple-step-card flex flex-col text-center items-center relative">
                    <div className="card-image-wrapper rounded-full flex p-4 justify-center items-center w-40 h-40 md:w-44 md:h-44 xd:w-52 xd:h-52">
                        <Fade triggerOnce delay={700}>
                            {/* <img
                                src="./images/home/simple-steps/selection.png"
                                alt="Solar and Battery Selection"
                                className="object-cover w-20 md:w-24"
                            /> */}
                             <Image
                                alt=""
                                src={SolarBattery}
                                width="96"
                                height="79"
                                className="object-contain"
                            />
                        </Fade>
                    </div>
                    <div className="max-w-260 pt-2 md:pt-5 lg:pb-12 pb-4">
                        <h3 className="mb-1.5 md:mb-2 text-titleNewColor leading-6 text-xl xd:text-2xl font-sfBold xxs:px-6">
                        Solar and Battery Selection
                        </h3>
                        <p className="text-gray-500 font-sfMedium text-lg leading-6 xd:text-xl">
                        Our algorithm suggests panels and batteries perfect for your home.
                        </p>
                    </div>
                    <span className="hidden 2md:flex md:absolute simplestep-arrow-icon">
                        <svg className="w-4" width="27" height="19" viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.122 18.4888C15.7753 18.148 15.6089 17.7362 15.6227 17.2534C15.6378 16.7705 15.8186 16.3587 16.1653 16.0179L21.0626 11.204H1.73355C1.24238 11.204 0.830369 11.0404 0.497528 10.7133C0.165843 10.3872 0 9.98281 0 9.5C0 9.01719 0.165843 8.6122 0.497528 8.28502C0.830369 7.95898 1.24238 7.79596 1.73355 7.79596H21.0626L16.122 2.93946C15.7753 2.59865 15.6019 2.19366 15.6019 1.72448C15.6019 1.25644 15.7753 0.852017 16.122 0.51121C16.4687 0.170403 16.8807 0 17.358 0C17.8342 0 18.2456 0.170403 18.5923 0.51121L26.5233 8.30717C26.6966 8.47758 26.8197 8.66218 26.8925 8.86099C26.9642 9.05979 27 9.27279 27 9.5C27 9.7272 26.9642 9.94021 26.8925 10.139C26.8197 10.3378 26.6966 10.5224 26.5233 10.6928L18.549 18.5314C18.2311 18.8438 17.8342 19 17.358 19C16.8807 19 16.4687 18.8296 16.122 18.4888Z" fill="#838588" />
                        </svg></span>
                </div>
                <div className="simple-step-card flex flex-col text-center items-center relative">
                    <div className="card-image-wrapper rounded-full flex p-1 justify-center items-center w-40 h-40 md:w-44 md:h-44 xd:w-52 xd:h-52">
                        <Fade triggerOnce delay={700}>
                            {/* <img
                                src="./images/home/simple-steps/get-financed_fast.png"
                                alt="Get Financed Fast"
                                className="object-cover w-24 md:w-36"
                            /> */}
                             <Image
                                alt=""
                                src={GetFinanced}
                                width="144"
                                height="131"
                                className="object-contain"
                            />
                        </Fade>
                    </div>
                    <div className="max-w-260 pt-2 md:pt-5 lg:pb-12 pb-4">
                        <h3 className="mb-1.5 md:mb-2 text-titleNewColor leading-6 text-xl xd:text-2xl font-sfBold w-52 mx-auto px-6 xd:px-6">
                        Get Financed Fast
                        </h3>
                        <p className="text-gray-500 font-sfMedium text-lg leading-6 xd:text-xl">
                        Get fully financed with credit approval from trusted brands in the industry with a few clicks.
                        </p>
                    </div>
                    <span className="hidden 2md:flex md:absolute simplestep-arrow-icon">
                        <svg className="w-4" width="27" height="19" viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.122 18.4888C15.7753 18.148 15.6089 17.7362 15.6227 17.2534C15.6378 16.7705 15.8186 16.3587 16.1653 16.0179L21.0626 11.204H1.73355C1.24238 11.204 0.830369 11.0404 0.497528 10.7133C0.165843 10.3872 0 9.98281 0 9.5C0 9.01719 0.165843 8.6122 0.497528 8.28502C0.830369 7.95898 1.24238 7.79596 1.73355 7.79596H21.0626L16.122 2.93946C15.7753 2.59865 15.6019 2.19366 15.6019 1.72448C15.6019 1.25644 15.7753 0.852017 16.122 0.51121C16.4687 0.170403 16.8807 0 17.358 0C17.8342 0 18.2456 0.170403 18.5923 0.51121L26.5233 8.30717C26.6966 8.47758 26.8197 8.66218 26.8925 8.86099C26.9642 9.05979 27 9.27279 27 9.5C27 9.7272 26.9642 9.94021 26.8925 10.139C26.8197 10.3378 26.6966 10.5224 26.5233 10.6928L18.549 18.5314C18.2311 18.8438 17.8342 19 17.358 19C16.8807 19 16.4687 18.8296 16.122 18.4888Z" fill="#838588" />
                        </svg></span>
                </div>
                <div className="simple-step-card flex flex-col text-center items-center relative">
                    <div className="card-image-wrapper rounded-full flex p-1 justify-center items-center w-40 h-40 md:w-44 md:h-44 xd:w-52 xd:h-52">
                    <Fade triggerOnce delay={700}>
                            {/* <img
                                src="./images/home/simple-steps/go-sign-in.png"
                                alt="Sign and Go Solar"
                                className="object-cover w-16"
                            /> */}
                            <Image
                                alt=""
                                src={GoSolar}
                                width="74"
                                height="77"
                                className="object-contain"
                            />
                        </Fade>
                    </div>
                    <div className="max-w-260 pt-2 md:pt-5 lg:pb-12 pb-4">
                        <h3 className="mb-1.5 md:mb-2 text-titleNewColor leading-6 text-xl xd:text-2xl font-sfBold  w-52 mx-auto px-6 xd:px-6">
                        Sign and Go Solar
                        </h3>
                        <p className="text-gray-500 font-sfMedium text-lg leading-6 xd:text-xl">
                        See your customized solar price and sign up instantly. You are now on your way to savings!
                        </p>
                    </div>
                </div>
            </div>

            <div className="sm:hidden my-auto trusted-partner-carousel pt-0">
                <Carousel responsive={responsive} showDots={true} arrows={false}>
                
                <div className="simple-step-card flex flex-col text-center items-center relative">
                    <div className="card-image-wrapper rounded-full flex px-4 py-2 sm:py-4 justify-center items-center w-48 h-40 xd:w-52 xd:h-52">
                        <Fade triggerOnce delay={500}>                            
                            <img
                                 src="./images/home/simple-steps/simple-process.png"
                                 alt="Quick and Easy Process"
                                className="object-cover w-24 md:w-24"
                            />
                        </Fade>
                    </div>
                    <div className="max-w-260 pt-2 md:pt-5 lg:pb-12 pb-4">
                        <h3 className="mb-1.5 md:mb-2 text-titleNewColor leading-6 text-xl xd:text-2xl font-sfBold xxs:px-6 mx-aut">
                        Quick and Easy Process
                        </h3>
                        <p className="text-gray-500 font-sfMedium text-lg leading-6 xd:text-xl">
                        Enter your address to start your solar journey.
                        </p>
                    </div>

                </div>
                <div className="simple-step-card flex flex-col text-center items-center relative">
                    <div className="card-image-wrapper rounded-full flex px-4 py-2 sm:py-4 justify-center items-center w-48 h-40 md:w-44 md:h-44 xd:w-52 xd:h-52">
                        <Fade triggerOnce delay={500}>
                            <img
                                src="./images/home/simple-steps/ai-powered-design.png"
                                alt="AI-Powered Design"
                                className="object-cover w-28 md:w-28"
                            />
                        </Fade>
                    </div>
                    <div className="max-w-260 pt-2 md:pt-5 lg:pb-12 pb-4">
                        <h3 className="mb-1.5 md:mb-2 text-titleNewColor leading-6 text-xl xd:text-2xl font-sfBold xxs:px-6 mx-auto">
                        AI-Powered Design
                        </h3>
                        <p className="text-gray-500 font-sfMedium text-lg leading-6 xd:text-xl">
                        Our AI tool gives you a fully designed system to your homes needs in the matter of seconds.
                        </p>
                    </div>

                </div>
                <div className="simple-step-card flex flex-col text-center items-center relative">
                    <div className="card-image-wrapper rounded-full flex px-4 py-2 sm:py-4 justify-center items-center w-48 h-40 md:w-44 md:h-44 xd:w-52 xd:h-52">
                        <Fade triggerOnce delay={700}>
                            <img
                                src="./images/home/simple-steps/selection.png"
                                alt="Solar and Battery Selection"
                                className="object-cover w-28 md:w-24"
                            />
                        </Fade>
                    </div>
                    <div className="max-w-260 pt-2 md:pt-5 lg:pb-12 pb-4">
                        <h3 className="mb-1.5 md:mb-2 text-titleNewColor leading-6 text-xl xd:text-2xl font-sfBold xxs:px-6">
                        Solar and Battery Selection
                        </h3>
                        <p className="text-gray-500 font-sfMedium text-lg leading-6 xd:text-xl">
                        Our algorithm suggests panels and batteries perfect for your home.
                        </p>
                    </div>

                </div>
                <div className="simple-step-card flex flex-col text-center items-center relative">
                    <div className="card-image-wrapper rounded-full flex p-1 justify-center items-center w-48 h-40 md:w-44 md:h-44 xd:w-52 xd:h-52">
                        <Fade triggerOnce delay={700}>                            <img
                                src="./images/home/simple-steps/get-financed_fast.png"
                                alt="Get Financed Fast"
                                className="object-cover w-28 md:w-40"
                            />
                        </Fade>
                    </div>
                    <div className="max-w-260 pt-2 md:pt-5 lg:pb-12 pb-4">
                        <h3 className="mb-1.5 md:mb-2 text-titleNewColor leading-6 text-xl xd:text-2xl font-sfBold px-6 xd:px-6">
                        Get Financed Fast
                        </h3>
                        <p className="text-gray-500 font-sfMedium text-lg leading-6 xd:text-xl">
                        Get fully financed with credit approval from trusted brands in the industry with a few clicks.
                        </p>
                    </div>
                </div>
                <div className="simple-step-card flex flex-col text-center items-center relative">
                    <div className="card-image-wrapper rounded-full flex p-1 justify-center items-center w-48 h-40 md:w-44 md:h-44 xd:w-52 xd:h-52">
                        <Fade triggerOnce delay={700}>
                            <img
                                src="./images/home/simple-steps/go-sign-in.png"
                                alt="Sign and Go Solar"
                                className="object-cover w-24 md:w-20"
                            />
                        </Fade>
                    </div>
                    <div className="max-w-260 pt-2 md:pt-5 lg:pb-12 pb-4">
                        <h3 className="mb-1.5 md:mb-2 text-titleNewColor leading-6 text-xl xd:text-2xl font-sfBold px-6 xd:px-6">
                        Sign and Go Solar
                        </h3>
                        <p className="text-gray-500 font-sfMedium text-lg leading-6 xd:text-xl">
                        See your customized solar price and sign up instantly. You are now on your way to savings!
                        </p>
                    </div>
                </div>
                </Carousel>
            </div>


            <div className="flex justify-center mt-5 sm:mt-0">
                <Fade triggerOnce delay={500}>
                    <Scrollspy
                        items={["SectionGetEstimate"]}
                        className="scrollContentData"
                        currentClassName="scrollContentData "
                    >
                        <Scroll href="/" type="id" element="SectionGetEstimate">
                            <button className="text-primary border-2 border-primary inline-block rounded font-sfMedium px-12 py-3 xd:px-16 xd:py-5 text-lg xd:text-2xl transition duration-500 ease-in-out hover:bg-primary hover:text-white transform hover:-translate-y-1 hover:scale-102">
                            Get My AI Design
                            </button>
                        </Scroll>
                    </Scrollspy>
                </Fade>
            </div>
          
        </section>

    )
};
export default SectionSimplesteps;