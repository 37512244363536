import React from "react";
import "react-toastify/dist/ReactToastify.css";
import GetEstimateForm from "../EstimateForm";


const SectionGetEstimateNow = () => {
  return (
    <section id="SectionGetEstimate" className="SectionGetEstimateNow-bg-home section-solar-saving">
      <div className="custom-container px-3 md:px-8 lg:px-8 xl:px-20">
      <div className="flex flex-col gap-8 sm:grid items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-x-1 md:gap-x-6 xl:gap-x-20 xd:gap-x-32 py-16 md:py-20">
        <div className="flex flex-col items-center text-white text-left px-2 md:px-8 px-4 w-full lg:max-w-lg max-w-560">
          <h3 className="text-center text-2xl leading-8 md:text-3xl lg:text-4xl xxs:tracking-wide text-white font-sfSemiBold pb-0 px-0 xd:px-8 w-full">
          <span>Experience Unbeatable</span> <span className="text-5xl sm:text-4xl md:text-5xl lg:text-6xl block text-primary text-center">Solar Savings</span> </h3>
          <div className="block font-sfRegular text-lg md:text-xl lg:text-2xl pt-1 xd:px-10">with Our AI-Powered Online Tool.</div>
          <div className="w-full max-w-3xl m-auto home-estimate-form-wrap">
            <GetEstimateForm layout="slider" />
          </div>
        </div>
        <div className="join-affiliate-home-bg home-estimate px-6 sm:px-8 py-8 sm:py-12 md:py-12 flex flex-col justify-center items-center rounded-lg w-full">
              <a href="/ambassador" className="w-full flex flex-row items-center relative justify-center">
                    <div className="text-white text-center w-full">
                    <h2 className="text-3xl md:text-4xl font-sfSemiBold">Join Our</h2>  
                    <span className="font-sfMedium text-xl md:text-2xxl">Ambassador Program and earn an extra</span> 
                    <h2 className="text-primary font-sfBold text-5xl sm:text-6xl px-2 leading-none relative pt-2 pb-3">$50</h2> 
                    <span className="text-base md:text-2xl font-sfLight">on your first referral sign up!</span>
                    <img src="../images/affiliate/announcement-icon-home.png" className="w-12 sm:w-20 absolute right-2 bottom-2" />
                    </div>  
                </a>  
        </div> 
        {/* <div className="text-sm w-full max-w-3xl m-auto text-white font-sfLight italic px-8">
        <span className="font-sfMedium">Disclaimer:</span> *Solar Quote's AI tool generates each solar system setup to cater to individual household requirements. 
        Thus, costs and potential savings can fluctuate based on various elements: the home's geographic location, 
        system dimensions, roof health and available area, orientation, nearby tree placements, available local 
        government grants, and the current utility charges in the area. Total savings on electricity costs are 
        not promised. Offered financing terms and product warranties might differ based on the region and may not 
        be available universally. For detailed insights and to access our complete terms and conditions, please 
        refer to our terms page.
        </div> */}
      </div>
      </div>
    </section>
  );
};

export default SectionGetEstimateNow;
