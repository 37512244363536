
import React from "react";
import { Fade } from "./../../components/Animation";
import Scrollspy from "react-scrollspy";
import Scroll from "../../pages/Scroll";
import Image from "next/image";
import unbeatableSaving from "../../public/images/home/unbeatable-saving.png";
import ownSolar from "../../public/images/home/own-solar.png";
import solarExpert from "../../public/images/home/solar-expert.png";

const SectionStreamlineYourSolarJorney = () => {
    return (
        <>


            <section className="bg-white">
                <div className="py-12 xl:py-24 xd:py-28 custom-container px-4 md:px-0">
                <h2 className="px-6 xxxs:px-0 xxs:px-4 md:px-10 xd:px-4 max-w-2xl md:max-w-4xl xd:max-w-5xl m-auto pb-8 md:pb-16 md:pt-4 text-center text-3xl sm:text-4xl lg:text-4xxl xl:text-5xl xd:text-5xxl xxs:text-center font-sfBold text-titleNewColor">
                Streamline Your Solar Journey With <span className="text-primary"> Solar Quote.</span> 
                    </h2>
                    <div className="section-wrapper flex flex-col-reverse 2md:grid 2md:grid-cols-2 gap-4 2md:gap-10 xl:gap-24 xd:gap-28">

                        <div className="2md:max-w-xl xd:max-w-xl 2xd:max-w-2xl">
                            <h2 className="section-heading 2xd:pr-8">
                            Unbeatable Savings: Up to 30% less than national average.
                            </h2>
                            <p className="section-paragraph md:mb-8 lg:pr-12 xd:pr-8 2xd:pr-20">
                            Unlock high-quality systems at prices up to 30% below the national average, thanks to our online platform that eliminates sales intervention. 
                            By cutting out intermediaries, we directly pass on the savings to you. 
                             Our AI-powered tool ensures you receive the best possible deal while meeting all your energy needs.
                            </p>

                         <Scrollspy
                        items={["quote-form"]}
                        className="scrollContentData md:block hidden"
                        currentClassName="scrollContentData "
                    >
                        <Scroll href="/" type="id" element="quote-form">
                            <button className="hidden md:inline-block transition duration-500 ease-in-out transform hover:-translate-y-1 hover:opacity-90 bg-secondary text-white rounded font-primaryMedium sm:px-6 py-2.5 xd:py-3 text-base md:text-lg xd:text-xl w-auto">
                            Unlock My Pricing
                            </button>
                        </Scroll>
                    </Scrollspy>
                        </div>
                        <Fade triggerOnce delay={500}>                            
                            <div className="my-auto">
                            <Image
                                alt="Get tax benefits"
                                src={unbeatableSaving}
                                className="object-contain w-full"
                            />
                                {/* <img src="./images/home/unbeatable-saving.png"
                                    className="object-contain w-full"
                                    
                                /> */}
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>

            <section className="bg-white">
                <div className="pb-12 xl:pb-24 xd:pb-28 custom-container px-4 md:px-0">
                    <div className="section-wrapper grid 2md:grid-cols-2 gap-4 2md:gap-10 xl:gap-24 xd:gap-28">
                        <div className="my-auto">
                        <Image
                               alt="efficiency of solar energy"
                                src={ownSolar}
                                className="object-contain w-full"
                            />
                            {/* <img src="./images/home/own-solar.png"
                                className="object-contain w-full"
                                
                            /> */}
                        </div>
                        <div className="2md:max-w-lg xd:max-w-xl 2xd:max-w-3xl">
                            <h2 className="section-heading xd:pr-8 2xd:pr-12">
                                Create your own solar design without the assistance of a representative.
                            </h2>
                            <p className="section-paragraph md:mb-8 xd:pr-12 2xd:pr-32">
                            The AI design tool is an integral feature of our instant quote and purchase platform. 
                            You don't have to do anything other than input your address, electricity bill amount 
                            and utility provider, then locate and set your house on the map. Our AI design tool 
                            uses LIDAR and irradiance technology to automatically generate an accurate, maximum-efficiency 
                            design for your home based on your unique environment. Once the design is complete, you can easily 
                            adjust the solar offset according to your need, no sales representative assistance necessary.
                            </p>

                        <Scrollspy
                        items={["quote-form"]}
                        className="scrollContentData md:block hidden"
                        currentClassName="scrollContentData "
                    >
                        <Scroll href="/" type="id" element="quote-form">
                            <button className="hidden md:inline-block transition duration-500 ease-in-out transform hover:-translate-y-1 hover:opacity-90 bg-secondary text-white rounded font-primaryMedium sm:px-6 py-2.5 xd:py-3 text-base md:text-lg xd:text-xl w-auto">
                            Run My AI Design
                            </button>
                        </Scroll>
                    </Scrollspy>
                        </div>


                    </div>
                </div>
            </section>

            <section className="bg-white">
                <div className="pb-12 xl:pb-24 xd:pb-28 custom-container px-4 md:px-0">
                    <div className="section-wrapper flex flex-col-reverse 2md:grid 2md:grid-cols-2 gap-4 2md:gap-10 xl:gap-24 xd:gap-28">

                        <div className="2md:max-w-lg xd:max-w-xl 2xd:max-w-2xl">
                            <h2 className="section-heading pr-6 md:pr-0">
                                Solar experts are available on live chat, phone or on email support.
                            </h2>
                            <p className="section-paragraph pr-4 md:pr-0 md:mb-8 2md:pr-8 xd:pr-12 2xd:pr-20">
                            Our team of experts are available via call, email, or live chat to help you with any questions or concerns 
                            you may have. Whether you need advice on your energy usage or technical support troubleshooting a problem, we're 
                            here to assist you. We pride ourselves on our ability to provide you with all the information you need to make informed decisions about your energy usage.
                            </p>
                            {/* <Link href="#">
                                <a className="hidden md:inline-block transition duration-500 ease-in-out transform hover:-translate-y-1 hover:opacity-90 bg-secondary text-white rounded font-primaryMedium sm:px-6 py-2.5 lg:py-2 xd:py-3 text-base md:text-lg xd:text-xl w-auto">
                                Get a Quote
                                </a>
                            </Link> */}
                         <Scrollspy
                        items={["quote-form"]}
                        className="scrollContentData md:block hidden"
                        currentClassName="scrollContentData "
                    >
                        <Scroll href="/" type="id" element="quote-form">
                            <button className="hidden md:inline-block transition duration-500 ease-in-out transform hover:-translate-y-1 hover:opacity-90 bg-secondary text-white rounded font-primaryMedium sm:px-6 py-2.5 xd:py-3 text-base md:text-lg xd:text-xl w-auto">
                            Get a Quote
                            </button>
                        </Scroll>
                    </Scrollspy>
                        </div>
                        <Fade triggerOnce delay={500}>                            
                            <div className="text-center">
                            <Image
                               alt="Solar experts"
                                src={solarExpert}
                                className="object-contain w-7/12 m-auto"
                            />
                                {/* <img src="./images/home/solar-expert.png"
                                    className="object-contain w-7/12 m-auto"
                                    alt="Get tax benefits"
                                /> */}
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>


        </>
    )
};
export default SectionStreamlineYourSolarJorney;