// import Link from "next/link";
import React from "react";
import Carousel from "react-multi-carousel";

const responsive = {
  Desktop: {
    breakpoint: { max: 4000, min: 640 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 640, min: 0 },
    items: 1,
  },
};

const SectionTestimonials = () => {
  return (
    <section className="bg-center bg-no-repeat bg-cover testimonials-bg">
      <div className="container mx-auto">
        <div className="pt-12 xxs:pt-28 pb-4 md:pb-6 px-6 lg:px-8 flex min-h-full flex-col w-full lg:min-h-650">
          <div>
          <div className="flex flex-col place-center items-center justify-center max-w-2xl xd:max-w-3xl m-auto h-full">
            <span>
              <svg className="w-10 xxs:w-16 xxs:h-16" viewBox="0 0 104 114" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M102 4C102 2.89543 101.105 2 100 2H4C2.89543 2 2 2.89543 2 4V81C2 82.1046 2.89543 83 4 83H30.5V109.5C30.5 110.309 30.9873 111.038 31.7346 111.348C32.482 111.657 33.3422 111.486 33.9142 110.914L61.8284 83H100C101.105 83 102 82.1046 102 81V4Z" stroke="white" strokeWidth="4" strokeLinejoin="round" />
                <path d="M56.5 37L63 23H73L66 37.5H76V56.5H56.5V37Z" fill="#FF932B" />
                <path d="M27 37L33.5 23H43.5L36.5 37.5H46.5V56.5H27V37Z" fill="#FF932B" />
              </svg>
            </span>
            <h4 className="text-primary text-xl xxs:text-3xl xd:text-4xl font-sfMedium my-2 xxs:my-5">Testimonials</h4>
            <h1 className="text-center text-white text-2xl leading-7 md:text-4xl xd:text-5xl font-sfBold mb-3 xxs:mb-4">
            See What People Are Saying About Us
            </h1>


          </div>
          <Carousel 
            responsive={responsive} 
            autoPlaySpeed={5000}
            showDots={true}
            arrows={false}
            swipeable={true}
            autoPlay={true}
            keyBoardControl={true}
            // customTransition="all .5"
            transitionDuration={500}
            infinite={true}
          >
            <div className="max-w-3xl xd:max-w-4xl mx-auto mb-10 xxs:mb-16 md:mb-20 lg:mb-44">
              <p className="text-white text-center font-sfRegular text-sm xxs:text-base lg:text-xl xd:text-2xl mb-4">I am so impressed with the service and expertise provided by Solar Quote. From the initial consultation to the final installation, their team was knowledgeable, professional, and attentive to all of my concerns. The process of switching to solar power can be daunting, but Solar Quote made it easy and stress-free.</p>
              <p className="text-primary text-center font-sfBold text-sm xxs:text-base lg:text-xl xd:text-2xl">Mike</p>
            </div>
            <div className="max-w-3xl xd:max-w-4xl mx-auto mb-10 xxs:mb-16 md:mb-20 lg:mb-44">
              <p className="text-white text-center font-sfRegular text-sm xxs:text-base lg:text-xl xd:text-2xl mb-4">Who knew solar installation could be this easy? I tried out Solar Quote's tool and was impressed with how easy everything was to understand. I chose the solar panels I wanted and had the option to add batteries as well. The solar installation process went exactly as described and I now save so much on electricity!</p>
              <p className="text-primary text-center font-sfBold text-sm xxs:text-base lg:text-xl xd:text-2xl">Lisa</p>
            </div>
            <div className="max-w-3xl xd:max-w-4xl mx-auto mb-10 xxs:mb-16 md:mb-20 lg:mb-44">
              <p className="text-white text-center font-sfRegular text-sm xxs:text-base lg:text-xl xd:text-2xl mb-4">I highly recommend Solar Quote to any homeowner looking into the possibility of solar panel installation. I appreciated that my home’s solar design was created automatically, it really helped me visualize how I wanted to go solar. I received help when I had questions and I didn't have to go through tons of calls to find out what was happening with my installation.</p>
              <p className="text-primary text-center font-sfBold text-sm xxs:text-base lg:text-xl xd:text-2xl">Daniel</p>
            </div>
            <div className="max-w-3xl xd:max-w-4xl mx-auto mb-10 xxs:mb-16 md:mb-20 lg:mb-44">
              <p className="text-white text-center font-sfRegular text-sm xxs:text-base lg:text-xl xd:text-2xl mb-4">The installation crew were very careful and professional when it came to actually getting my solar installed. I think it’s wonderful that Solar Quote doesn’t just make buying solar easy, they also make sure to follow through till the end. If you want to buy solar conveniently, Solar Quote is the company you should go for.</p>
              <p className="text-primary text-center font-sfBold text-sm xxs:text-base lg:text-xl xd:text-2xl">Evelyn</p>
            </div>
          </Carousel>
        </div>
        </div>
      </div>
    </section>
  );
};

export default SectionTestimonials;
