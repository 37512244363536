import React from "react";
import { Fade } from "./../../components/Animation";
import Carousel from "react-multi-carousel";
import 'animate.css';

const responsive = {
    Desktop: {
        breakpoint: { max: 4000, min: 640 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 640, min: 0 },
        items: 1,
    },
};

const SectionCustomerprefer = () => {
    return (
        <section className="bg-bluelight350 pt-8 pb-12 xl:pb-24 xl:pt-28">
            <div className="custom-container px-4 md:px-0">
                <div className="text-center pb-4 sm:px-6 max-w-5xl xd:max-w-6xl m-auto mt-8 sm:mt-12">
                    <h2 className="pb-2 text-3xl xxs:text-4xl md:text-5xl xd:text-5xxl xxs:text-center font-sfBold text-titleNewColor">
                        Why do customers prefer
                        <span className="text-primary"> Solar Quote?</span>
                    </h2>
                    <p className="text-titleColor pt-2 pb-4 text-base xxs:text-xl font-sfRegular md:px-14 xd:px-24">Say goodbye to the inconvenience and cost of sales representatives. With Solar Quote's instant quote tool, you can get exactly what you need in just a few seconds—no hidden fees or complex processes. Go solar now and be in control of your purchase journey.</p>
                </div>
                <div className="section-wrapper hidden sm:flex flex-wrap justify-center gap-y-2 lg:flex-nowrap px-12">
                    <div className="w-5/6 xxs:w-1/2 lg:w-1/5 sm:px-2 xl:px-4 py-4 max-w-xs xd:max-w-md mx-auto">
                        <div className="customer-prefer-icon-wrapper rounded-md grid justify-center items-center xl:w-full py-8 px-12 bg-white shadow-xl">
                            <Fade triggerOnce delay={700}>
                                <div className="h-24 flex items-center justify-center">
                                <img 
                                    src="./images/home/customer-prefer/experience-easier.png"
                                    alt="no img"
                                    className="customer-prefer-icon w-full h-full object-contain"
                                />
                                </div>
                            </Fade>
                        </div>
                        <h3 className="text-titleNewColor text-lg leading-6 xd:text-xl font-sfBold mt-5 xl:mt-8 xd:mt-10 md:mb-2 text-center 2xd:px-6">
                        Experience an easier and quicker way to purchase solar energy.
                        </h3>

                    </div>
                    <div className="w-5/6 xxs:w-1/2 lg:w-1/5 px-2 sm:px-2 xl:px-4 py-4 max-w-xs xd:max-w-md mx-auto">
                        <div className="customer-prefer-icon-wrapper rounded-md grid justify-center items-center xl:w-full py-8 px-12 bg-white shadow-xl">
                             <Fade triggerOnce delay={700}>
                                <div className="h-24 flex items-center justify-center">
                                <img
                                    src="./images/home/customer-prefer/average.png"
                                    alt="no img"
                                    className="customer-prefer-icon w-full h-full object-contain"
                                />
                                </div>
                            </Fade>
                        </div>
                        <h3 className="text-titleNewColor text-lg leading-6 xd:text-xl font-sfBold mt-5 xl:mt-8 xd:mt-10 md:mb-2 text-center 2xd:px-8">
                        Go solar at 20-30% lower prices than the national average.
                        </h3>
                    </div>
                    <div className="w-5/6 xxs:w-1/2 lg:w-1/5 px-2 sm:px-2 xl:px-4 py-4 max-w-xs xd:max-w-md mx-auto">
                        <div className="customer-prefer-icon-wrapper rounded-md grid justify-center items-center xl:w-full py-8 px-12 bg-white shadow-xl">
                            <Fade triggerOnce delay={700}>
                            <div className="h-24 flex items-center justify-center">
                            <img
                                    src="./images/home/customer-prefer/financing.png"
                                    alt="no img"
                                    className="customer-prefer-icon w-full h-full object-contain"
                                />
                            </div>
                            </Fade>
                        </div>
                        <h3 className="text-titleNewColor text-lg leading-6 xd:text-xl font-sfBold mt-5 xl:mt-8 xd:mt-10 md:mb-2 text-center 2xd:px-10">
                        Apply for financing fully online with instant credit approval.
                        </h3>

                    </div>
                    <div className="w-5/6 xxs:w-1/2 lg:w-1/5 px-2 sm:px-2 xl:px-4 py-4 max-w-xs xd:max-w-md mx-auto">
                        <div className="customer-prefer-icon-wrapper rounded-md grid justify-center items-center xl:w-full py-8 px-12 bg-white shadow-xl">
                            <Fade triggerOnce delay={700}>
                                <div className="h-24 flex items-center justify-center">
                                <img
                                    src="./images/home/customer-prefer/ai-powered.png"
                                    alt="no"
                                    className="customer-prefer-icon w-full h-full object-contain"
                                />
                                </div>
                            </Fade>
                        </div>
                        <h3 className="text-titleNewColor text-lg leading-6 xd:text-xl font-sfBold mt-5 xl:mt-8 xd:mt-10 md:mb-2 text-center 2xd:px-8">
                        Get highly-efficient AI powered designs in a few clicks.
                        </h3>
                    </div>

                    <div className="w-5/6 xxs:w-1/2 lg:w-1/5 px-2 sm:px-2 xl:px-4 py-4 max-w-xs xd:max-w-md mx-auto">
                        <div className="customer-prefer-icon-wrapper rounded-md grid justify-center items-center xl:w-full py-8 px-12 bg-white shadow-xl">
                        <Fade triggerOnce delay={700}>                                <div className="h-24 flex items-center justify-center">
                                <img
                                    src="./images/home/customer-prefer/no-interaction.png"
                                    alt="no"
                                    className="customer-prefer-icon w-full h-full object-contain"
                                />
                                </div>
                            </Fade>
                        </div>
                        <h3 className="text-titleNewColor text-lg leading-6 xd:text-xl font-sfBold mt-5 xl:mt-8 xd:mt-10 md:mb-2 text-center 2xd:px-4">
                        No interaction with sales reps, but we have experts to help you if you need.
                        </h3>
                    </div>
                </div>



                <div className="sm:hidden my-auto trusted-partner-carousel">
                <Carousel responsive={responsive} showDots={true} arrows={false}>
                <div className="w-5/6 px-2 py-4 max-w-xs mx-auto">
                        <div className="customer-prefer-icon-wrapper rounded-md grid justify-center items-center xl:w-full p-8 bg-white shadow-xl">
                            <Fade triggerOnce delay={700}>
                                <div className="h-24 flex items-center justify-center">
                                <img
                                    src="./images/home/customer-prefer/experience-easier.png"
                                    alt="no img"
                                    className="customer-prefer-icon w-full h-full object-contain"
                                />
                                </div>
                            </Fade>
                        </div>
                        <h3 className="text-titleNewColor text-lg leading-6 md:px-6 xd:text-xl font-sfBold mt-5 xl:mt-8 xd:mt-10 md:mb-2 text-center">
                        Experience an easier and quicker way to purchase solar energy.
                        </h3>

                    </div>
                    <div className="w-5/6 xxs:w-1/2  px-2 sm:px-4 xl:px-8 py-4 max-w-xs mx-auto">
                        <div className="customer-prefer-icon-wrapper rounded-md grid justify-center items-center xl:w-full p-8 bg-white shadow-xl">
                            <Fade triggerOnce delay={700}>                                <div className="h-24 flex items-center justify-center">
                                <img
                                    src="./images/home/customer-prefer/average.png"
                                    alt="no img"
                                    className="customer-prefer-icon w-full h-full object-contain"
                                />
                                </div>
                            </Fade>
                        </div>
                        <h3 className="text-titleNewColor text-lg leading-6 md:px-6 xd:text-xl font-sfBold mt-5 xl:mt-8 xd:mt-10 md:mb-2 text-center">
                        Go solar at 20-30% lower prices than the national average.
                        </h3>

                    </div>
                    <div className="w-5/6 xxs:w-1/2 px-2 sm:px-4 py-4 max-w-xs mx-auto">
                        <div className="customer-prefer-icon-wrapper rounded-md grid justify-center items-center xl:w-full p-8 bg-white shadow-xl">
                            <Fade triggerOnce delay={700}>                            <div className="h-24 flex items-center justify-center">
                            <img
                                    src="./images/home/customer-prefer/financing.png"
                                    alt="no img"
                                    className="customer-prefer-icon w-full h-full object-contain"
                                />
                            </div>
                            </Fade>
                        </div>
                        <h3 className="text-titleNewColor text-xl leading-6 md:px-6 xd:text-2xl font-sfBold mt-5 xl:mt-8 xd:mt-10 md:mb-2 text-center">
                        Apply for financing fully online with instant credit approval.
                        </h3>

                    </div>
                    <div className="w-5/6 xxs:w-1/2 px-2 sm:px-4 py-4 max-w-xs mx-auto">
                        <div className="customer-prefer-icon-wrapper rounded-md grid justify-center items-center xl:w-full p-8 bg-white shadow-xl">
                            <Fade triggerOnce delay={700}>                                <div className="h-24 flex items-center justify-center">
                                <img
                                    src="./images/home/customer-prefer/ai-powered.png"
                                    alt="no"
                                    className="customer-prefer-icon w-full h-full object-contain"
                                />
                                </div>
                            </Fade>
                        </div>
                        <h3 className="text-titleNewColor text-xl leading-6 md:px-6 xd:text-2xl font-sfBold mt-5 xl:mt-8 xd:mt-10 md:mb-2 text-center">
                        Get highly-efficient AI powered designs in a few clicks.
                        </h3>
                    </div>
                    <div className="w-5/6 xxs:w-1/2 px-2 sm:px-4 py-4 max-w-xs mx-auto">
                        <div className="customer-prefer-icon-wrapper rounded-md grid justify-center items-center xl:w-full p-8 bg-white shadow-xl">
                            <Fade triggerOnce delay={700}>                                <div className="h-24 flex items-center justify-center">
                                <img
                                    src="./images/home/customer-prefer/no-interaction.png"
                                    alt="no"
                                    className="customer-prefer-icon w-full h-full object-contain"
                                />
                                </div>
                            </Fade>
                        </div>
                        <h3 className="text-titleNewColor text-xl leading-6 md:px-6 xd:text-2xl font-sfBold mt-5 xl:mt-8 xd:mt-10 md:mb-2 text-center">
                        No interaction with sales reps, but we have experts to help you if you need.
                        </h3>
                    </div>
                </Carousel>
            </div>





            </div>
        </section>

    )
};
export default SectionCustomerprefer;
