import React from "react";
import { Fade } from "./../../components/Animation";
import Link from "next/link";
// import GetEnquire from "./GetEnquire";
import Carousel from "react-multi-carousel";
import Image from "next/image";
import Increaseimg from "../../public/images/home/increase-a.png";
import Energyefficiency1 from "../../public/images/home/energy-efficiency-1.png";
import Energyefficiency2 from "../../public/images/home/energy-efficiency-2.png";
import Energyefficiency3 from "../../public/images/home/energy-efficiency-3.png";
import Energyefficiency4 from "../../public/images/home/energy-efficiency-4.png";
import Energyefficiency5 from "../../public/images/home/energy-efficiency-5.png";

const responsive = {
    Desktop: {
        breakpoint: { max: 4000, min: 640 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 640, min: 0 },
        items: 1,
    },
};


const IncreaseEnergy = () => {

    return (
        <div className="custom-container px-4 md:px-0 py-12 xl:py-20">
            {/* <div className="section-wrapper"> */}
                <div className="section-wrapper lg:bg-white md:rounded-3xl md:p-8 xd:p-10 lg:shadow-md">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 sm:gap-12">
                        <div className="hidden md:flex items-start">
                            <Fade triggerOnce delay={500}>
                            <Image
                                alt=""
                                src={Increaseimg}
                                width="584"
                                height="558"
                                className="object-contain"
                            />
                               
                            </Fade>
                        </div>
                        <div className="flex flex-col justify-between home-increae-energy-carousel avi">
                            <Carousel
                                responsive={responsive} 
                                autoPlaySpeed={5000}
                                showDots={true}
                                arrows={false}
                                swipeable={true}
                                autoPlay={true}
                                keyBoardControl={true}
                                // customTransition="all .5"
                                transitionDuration={500}
                                infinite={true}
                            containerClass="carousel-container-of-increase-Energy-section"
                            //   removeArrowOnDeviceType={["tablet", "mobile"]}
                            dotListClass="custom-dot-list"
                            >
                                 <Image
                                    alt=""
                                    src={Energyefficiency1}
                                    // width="584"
                                    // height="558"
                                    className="carousal-item object-contain w-full"
                                />
                                 <Image
                                    alt=""
                                    src={Energyefficiency2}
                                    // width="584"
                                    // height="558"
                                    className="carousal-item object-contain w-full"
                                />
                                <Image
                                    alt=""
                                    src={Energyefficiency3}
                                    // width="584"
                                    // height="558"
                                    className="carousal-item object-contain w-full"
                                />
                                <Image
                                    alt=""
                                    src={Energyefficiency4}
                                    // width="584"
                                    // height="558"
                                    className="carousal-item object-contain w-full"
                                />
                                <Image
                                    alt=""
                                    src={Energyefficiency5}
                                    // width="584"
                                    // height="558"
                                    className="carousal-item object-contain w-full"
                                />
                                {/* <img
                                    src="./images/home/energy-efficiency-1.png"
                                    className="carousal-item object-contain w-full"
                                />
                                <img
                                    src="./images/home/energy-efficiency-2.png"
                                    className="carousal-item object-contain w-full"
                                />
                                
                                 <img
                                    src="./images/home/energy-efficiency-3.png"
                                    className="carousal-item object-contain w-full"
                                /> */}
                                  {/* <img
                                    src="./images/home/energy-efficiency-4.png"
                                    className="carousal-item object-contain w-full"
                                />
                                 <img
                                    src="./images/home/energy-efficiency-5.png"
                                    className="carousal-item object-contain w-full"
                                /> */}
                                  <div className="join-affiliate-home-bg px-4 py-6 md:py-8 flex flex-col justify-center items-center rounded">
                                          <a href="/ambassador" className="w-full flex flex-row items-center relative">
                                                <div className="text-white text-center w-full">
                                                <h2 className="text-2xxxl md:text-4xl font-sfSemiBold">Join Our</h2>  
                                                <span className="font-sfMedium text-xl md:text-2xxl">Ambassador Program and earn an extra</span> 
                                                <h2 className="text-primary font-sfBold text-4xl md:text-5xl sm:text-6xl px-2 leading-none relative pt-1 md:pt-2 pb-1 md:pb-2">$50</h2> 
                                                <span className="text-xl md:text-2xl font-sfRegular">on your first referral sign up!</span>
                                                <img src="../images/affiliate/announcement-icon-home.png" className="w-12 sm:w-20 absolute right-2 bottom-2" />
                                                </div>  
                                            </a>  
                                    </div> 
                            </Carousel>



                            <div className="text-titleColor text-2xl leading-7 sm:text-3xl 2xl:text-4xl xd:text-4xxl mt-6 xd:mt-8 mb-2 xxs:mb-3 font-sfSemiBold xxxs:font-sfBold">Increase the energy efficiency of your home by harnessing the power of the sun with cutting-edge solar panels.</div>
                            <div className="flex justify-end mt-auto">
                                <Link href="/solar">
                                    <a className="inline-block transition duration-500 ease-in-out transform hover:-translate-y-1 hover:opacity-90 text-titleColor bg-primary rounded font-sfSemiBold px-3 sm:px-6 py-2.5 lg:py-2.5 xd:py-3 mt-5 text-lg xd:text-xl w-full lg:w-auto text-center">
                                        Learn More
                                    </a>
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>
            {/* </div> */}
        </div >



    )
};
export default IncreaseEnergy;