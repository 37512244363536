import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const SectionHomeFAQs = () => {
  return (
    <section className="bg-white py-12 xl:py-24">
      <div className="custom-container">
        <div className='section-wrapper'>
        <h4 className='text-primary text-xl md:text-3xl xd:text-4xl font-sfSemiBold my-2 xxs:my-2 text-center'>FAQs</h4>
        <h1 className="text-center text-2xl leading-8 xxs:text-3xl md:text-4xxl lg:text-5xl xd:text-5xxl xxs:tracking-wide text-titleColor font-sfBold max-w-4xl mx-auto px-4 sm:px-0 mb-5 xxs:mb-8 sm:mb-10 md:mb-16">
        How can we help you?
        </h1>
        <div className="custom-accordion pl-3 pr-2 sm:px-4 max-w-5xl xd:max-w-6xl mx-auto">
          <Accordion
            preExpanded={["faq-1"]}
            allowMultipleExpanded={true}
            allowZeroExpanded={true}
          >
                        <AccordionItem
              className="bg-white pb-2 sm:mb-4 px-2 2md:px-2"
              id="faq-1"
              uuid="faq-1"
            >
              <AccordionItemHeading>
                <AccordionItemButton className="focus:outline-none accordion__button relative">
                  <div>
                    <h3 className="w-4/5 sm:w-full text-base leading-5 xxs:text-lg xxs:leading-6 xd:text-2xl flex flex-row items-center text-titleColor hover:text-primary font-primarySemiBold">
                    How accurate is the AI design given by Solar Quote? 
                    </h3>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="text-sm xxs:text-lg xd:text-xl text-titleColor pr-10 sm:pr-16 xxs:leading-6">
                Solar Quote’s innovative AI tool generates 100% accurate solar designs for homes using LIDAR technology. When 
                working with the AI tool, the design provided is automatically optimized for maximum efficiency.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
             <AccordionItem
              className="bg-white pb-2 sm:mb-4 px-2 2md:px-2"
              id="faq-2"
              uuid="faq-2"
            >
              <AccordionItemHeading>
                <AccordionItemButton className="focus:outline-none accordion__button relative">
                  <div>
                    <h3 className="w-4/5 sm:w-full text-base leading-5 xxs:text-lg xxs:leading-6 xd:text-2xl flex flex-row items-center text-titleColor hover:text-primary font-primarySemiBold">
                    How much can I save by going solar online? 
                    </h3>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="text-sm xxs:text-lg xd:text-xl text-titleColor pr-10 sm:pr-16 xxs:leading-6">
                Going solar online eliminates many hidden costs and charges that you may not even be aware of when consulting offline services. As a result, we provide solar installations at prices which are 20-30% lower than the national average.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem
              className="bg-white pb-2 sm:mb-4 px-2 2md:px-2"
              id="faq-3"
              uuid="faq-3"
            >
              <AccordionItemHeading>
                <AccordionItemButton className="focus:outline-none accordion__button relative">
                  <div>
                    <h3 className="w-4/5 sm:w-full text-base leading-5 xxs:text-lg xxs:leading-6 xd:text-2xl flex flex-row items-center text-titleColor hover:text-primary font-primarySemiBold">
                    What are the benefits of going solar online? 
                    </h3>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="text-sm xxs:text-lg xd:text-xl text-titleColor pr-10 sm:pr-16 xxs:leading-6">
                Purchasing a solar installation online allows homeowners more flexibility when it comes to materials, financing options and solar design. Buying solar online is incredibly 
                convenient, you can use the AI tool from home and log in to the customer portal anytime, anywhere. Lastly, online solar eliminates the need to interact with pushy salesmen 
                looking to make a commission, ensuring you have complete control over your purchase journey.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem
              className="bg-white pb-2 sm:mb-4 px-2 2md:px-2"
              id="faq-4"
              uuid="faq-4"
            >
              <AccordionItemHeading>
                <AccordionItemButton className="focus:outline-none accordion__button relative">
                  <div>
                    <h3 className="w-4/5 sm:w-full text-base leading-5 xxs:text-lg xxs:leading-6 xd:text-2xl flex flex-row items-center text-titleColor hover:text-primary font-primarySemiBold">
                    What happens to my solar panels during extreme weather conditions? 
                    </h3>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="text-sm xxs:text-lg xd:text-xl text-titleColor pr-10 sm:pr-16 xxs:leading-6">
                Solar panels are designed to withstand harsh weather conditions such as high-speed winds, hail,  snow, and extremely hot or cold conditions. However, it's important to schedule maintenance checkups to ensure that your solar panels are working properly after an extreme weather event.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem
              className="bg-white pb-2 sm:mb-4 px-2 2md:px-2"
              id="faq-5"
              uuid="faq-5"
            >
              <AccordionItemHeading>
                <AccordionItemButton className="focus:outline-none accordion__button relative">
                  <div>
                    <h3 className="w-4/5 sm:w-full text-base leading-5 xxs:text-lg xxs:leading-6 xd:text-2xl flex flex-row items-center text-titleColor hover:text-primary font-primarySemiBold">
                    How much does it cost to switch to solar?
                    </h3>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="text-sm xxs:text-lg xd:text-xl text-titleColor pr-10 sm:pr-16 xxs:leading-6">
                The cost of switching to solar varies depending on factors such as the size of the solar installation, the type of solar panels used and whether or not you add battery backup. You can obtain an instant solar quote from us to get an idea of the costs. 
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem
              className="bg-white pb-2 sm:mb-4 px-2 2md:px-2"
              id="faq-6"
              uuid="faq-6"
            >
              <AccordionItemHeading>
                <AccordionItemButton className="focus:outline-none accordion__button relative">
                  <div>
                    <h3 className="w-4/5 sm:w-full text-base leading-5 xxs:text-lg xxs:leading-6 xd:text-2xl flex flex-row items-center text-titleColor hover:text-primary font-primarySemiBold">
                    Will switching to solar reduce my electricity bill? 
                    </h3>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="text-sm xxs:text-lg xd:text-xl text-titleColor pr-10 sm:pr-16 xxs:leading-6">
                Yes, switching to solar can significantly reduce your electricity bill by offsetting non-renewable utility electricity with solar energy. Solar installations often  produce more energy than you consume. You can get paid for the excess energy that you feed back to the grid by participating in net metering.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem
              className="bg-white pb-2 sm:mb-4 px-2 2md:px-2"
              id="faq-7"
              uuid="faq-7"
            >
              <AccordionItemHeading>
                <AccordionItemButton className="focus:outline-none accordion__button relative">
                  <div>
                    <h3 className="w-4/5 sm:w-full text-base leading-5 xxs:text-lg xxs:leading-6 xd:text-2xl flex flex-row items-center text-titleColor hover:text-primary font-primarySemiBold">
                    Will switching to solar increase my home's value? 
                    </h3>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="text-sm xxs:text-lg xd:text-xl text-titleColor pr-10 sm:pr-16 xxs:leading-6">
                Yes, a solar installation can increase the value of your home by 4%. Solar installations are a desirable feature that attract potential buyers, and solar may even increase your home's energy efficiency rating.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            {/* <AccordionItem
              className="bg-white pb-2 sm:mb-4 px-2 2md:px-2"
              id="faq-5"
              uuid="faq-5"
            >
              <AccordionItemHeading>
                <AccordionItemButton className="focus:outline-none accordion__button relative">
                  <div>
                    <h3 className="w-4/5 sm:w-full text-base leading-5 xxs:text-lg xxs:leading-6 xd:text-2xl flex flex-row items-center text-titleColor hover:text-primary font-primarySemiBold">
                      How much does it cost to switch to solar?
                    </h3>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="text-sm xxs:text-lg xd:text-xl text-titleColor pr-10 sm:pr-16 xxs:leading-6">
                  The cost of switching to solar can vary depending on factors such as the size of the solar installation, the type of solar panels used, and whether the system is purchased or leased. It's important to get quotes from multiple solar companies to compare costs and find the best option for your needs.
                </p>
              </AccordionItemPanel>
            </AccordionItem> */}
          </Accordion>
        </div>
      </div>
      </div>
    </section>
  );

}

export default SectionHomeFAQs;


