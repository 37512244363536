// import Link from "next/link";
import React from "react";
import Scrollspy from "react-scrollspy";
import Scroll from "../../pages/Scroll";
// import protectHome from "../../public/images/home/section-efficient-home-bg.jpg"

const SectionEfficientHome = () => {

  // const rightSectionRef = useRef<HTMLDivElement|null>(null);
  // const leftSectionRef = useRef<HTMLDivElement|null>(null);

  // useEffect(() => {

    // function handleMouseMove(event: MouseEvent) {
      
    //   const newPos = event.clientX;
      
      // if (sliderPositon > newPos ) {
    //   const newWidth = (newPos / 12);
    //   console.log(newWidth);
    //   if (leftSectionRef?.current && newWidth > 10 && newWidth < 80) {
    //     leftSectionRef.current.style.width = `${newWidth}%`;
    //   }

    // };
  
  
    // function handleMouseUp (){
    //   document.removeEventListener('mousemove', handleMouseMove);
    //   document.removeEventListener('mouseup', handleMouseUp);
    // };

    // function handleDragEvent(event:MouseEvent) {
    //   console.log(event, 'event');
    //   document.addEventListener('mousemove', handleMouseMove);
    //   document.addEventListener('mouseup', handleMouseUp);
    // }

    // const rightSection =rightSectionRef?.current
    // if (!rightSection) {
    //   return;
    // }
  //   rightSection.addEventListener('mousedown',handleDragEvent)

  //   return () => {
  //     rightSection.removeEventListener('mousedown',handleDragEvent)
  //   }
  // }, []);

  // function handleDragEvent(event:any) {
  //   console.log(event, 'event');
  //   document.addEventListener('mousemove', handleMouseMove);
  //     document.addEventListener('mouseup', handleMouseUp);
  // }
  // function handleMouseMove() {
  //   console.log('move')
  // }
  // function handleMouseUp(){}

  return (
    <section className="hidden md:grid bg-center bg-no-repeat bg-cover section-efficient-home-bg page-min-height min-h-full place-items-center"
    // style={{
    //   backgroundImage: `url(${protectHome})`
    // }}
    >
      <div className="flex min-h-full w-full">
        <div className="w-7/12" 
        // ref={leftSectionRef}
        >

        </div>
        <div className="section-efficient-home w-5/12 relative right-section right-opacity" 
        // ref={rightSectionRef}
        >
          <div className="grid min-h-full justify-items-end">
            <div className="grid grid-cols-1">
              <div className="p-12 relative inner-right-opacity"> 
                <div className="flex flex-col justify-center h-full relative z-10 lg:pr-12 2xl:pr-16 xd:pr-20 2xd:pr-28">
                  <h5 className="text-white font-sfMedium text-xl xxs:text-2xl lg:text-3xl xl:text-4xl xd:text-5xl mt-4 mb-2"> <span className="text-primary">Protect your home and family</span>  from power outages and unstable utility grids.</h5>
                  <p className="text-white font-sfMedium text-lg xxs:text-xl lg:pr-8 2xl:pr-8 xd:pr-16 2xd:pr-36">Bundle your solar and energy storage at unbeatable prices.</p>
                  <div className="flex items-center mt-4">
                  <Scrollspy
                        items={["quote-form"]}
                        className="scrollContentData md:block hidden"
                        currentClassName="scrollContentData "
                    >
                        <Scroll href="/" type="id" element="quote-form">
                            <button className="inline-block transition duration-500 ease-in-out transform hover:-translate-y-1 hover:opacity-90 text-titleColor bg-primary rounded font-sfSemiBold sm:px-6 py-2.5 lg:py-2.5 xd:py-3 mt-2 text-base md:text-lg xd:text-xl w-auto">
                            Bundle My Home
                            </button>
                        </Scroll>
                    </Scrollspy>
                    {/* <a href="" className="inline-block transition duration-500 ease-in-out transform hover:-translate-y-1 hover:opacity-90 text-titleColor bg-primary rounded font-sfSemiBold sm:px-6 py-2.5 lg:py-2 xd:py-3 mt-2 text-base md:text-lg xd:text-xl w-auto">Learn More</a> */}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionEfficientHome;
